<template>
    <div>
      <!-- <v-card class="ma-3 pa-3"> -->
      <!-- <h4>{{ id }}</h4>
      <h5>{{ user }}</h5>
      <h5>{{ nousers }}</h5> -->
      <v-card class="ma-3 pa-3">
        <v-card-title primary-title>
          <div class="headline primary--text">Profile for {{ name }}</div>
        </v-card-title>
        <v-card-text>
          <template>
            <div class="my-3">
              <div class="subheading secondary--text text--lighten-2">
                Username
              </div>
              <div class="title primary--text text--darken-2" v-if="user">
                {{ user.email }}
              </div>
              <div class="title primary--text text--darken-2" v-else>-----</div>
            </div>
  
            <v-form
              v-model="valid"
              @submit.prevent="handleSubmit(save)"
              lazy-validation
              ref="editform"
            >
              <v-text-field
                label="E-mail"
                v-model="formuser.email"
                required
                :rules="emailRules"
              />
              <v-select
                label="Country"
                v-model="formuser.country"
                :items="Country"
                required
                :rules="required"
              />
              <div class="subheading primary--text text--lighten-1">
                User is superuser
                <span v-if="formuser.is_superuser"
                  >(currently is a superuser)</span
                ><span v-else>(currently is not a superuser)</span>
              </div>
              <v-checkbox label="Is Superuser" v-model="formuser.is_superuser" />
              <div class="subheading primary--text text--lighten-1">
                User is active
                <span v-if="formuser.is_active">(currently active)</span
                ><span v-else>(currently not active)</span>
              </div>
              <v-checkbox label="Is Active" v-model="formuser.is_active" />
              <div class="subheading primary--text text--lighten-1">
                User is verified
                <span v-if="formuser.is_verified">(currently active)</span
                ><span v-else>(currently not active)</span>
              </div>
              <v-checkbox label="Is Verified" v-model="formuser.is_verified" />

              <!-- Set the change of password -->
              

              <v-layout align-center>
                <v-flex shrink>
                  <v-checkbox v-model="setPassword" class="mr-2" />
                </v-flex>
                <v-flex>
                  <v-text-field
                    :disabled="!setPassword"
                    type="password"
                    ref="password"
                    label="Set Password"
                    v-model="password1"
                    :rules="[rules.required, rules.minin, passwordMatchRule]"
                  />
  
                  <v-text-field
                    v-show="setPassword"
                    type="password"
                    label="Confirm Password"
                    v-model="password2"
                    :rules="[rules.required, rules.minin, passwordMatchRule]"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="invite" color="blue">Invite User</v-btn>
          <v-btn @click="cancel" color="error">Cancel</v-btn>
          <v-btn @click="reset" color="biscuit">Reset</v-btn>
          <v-btn @click="submit" :disabled="!valid" color="guacamole">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  import { get, sync } from "vuex-pathify";
  import { User } from "@/models/users.js";
  import { diffObject } from '@/utils/general.js';
  import { Country } from "@/utils/selectoptions.js";
  
  
  export default {
    data() {
      return {
        valid: true,
        required: [(v) => !!v || "Field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        rules: {
          required: (value) => !!value || "Field is required.",
          minin: (v) => (v && v.length >= 8) || "Min 8 characters",
        },
        //formuser: new User(),
        formuser: {},
        // fullName: '',
        // email: '',
        // isActive: true,
        // isSuperuser: false,
        setPassword: false,
        password1: "",
        password2: "",
        Country: Country
        // foo: false,
      };
    },
    async mounted() {
      // We have refreshed, so fetch users again :)
      if (this.nousers === 0) {
        await this.$store.dispatch("adminusers/fetchUsers");
      }
      this.reset();
    },
    methods: {
      reset() {
        this.setPassword = false;
        this.password1 = "";
        this.password2 = "";
        this.$refs.editform.reset();
        if (this.user) {
          this.formuser.email = this.user.email;
          this.formuser.country = this.user.country;
          this.formuser.is_active = this.user.is_active;
          this.formuser.is_superuser = this.user.is_superuser;
          this.formuser.is_verified = this.user.is_verified;
        }
      },
      cancel() {
        this.$router.back();
      },
      async submit() {
        this.$refs.editform.validate()
  
        if (this.password1)
          this.formuser.password = this.password1
  
        // Take the difference between the objects, since the patch
        // operation needs only the fields that change.
        const editedUser = diffObject(this.user, this.formuser)
  
        if (this.password1)
          editedUser.password = this.password1
  
        try {
          this.$store.dispatch('adminusers/editUser', 
            {userid: this.user.id, 
            payload: editedUser})
            .then(this.$router.push('/dashboard/users/'))
        } catch (error) {
          console.log(error)
        }
      },
      async invite() {
        try {
          this.$store.dispatch('adminusers/inviteUser',
           {userid: this.user.id})
           .then(this.$router.push('/dashboard/users/'))
        } catch (error) {
          console.log(error)
        }
      }
    },
    computed: {
      ...get("route", ["params@id"]),
      ...get("adminusers", ["nousers", "loading"]),
      ...get("newauth", {
        name: "userProfile.email"
      }),
      // ...get('adminusers', {
      //   user: 'getUser[:id]'
      // })
      user() {
        return this.$store.get("adminusers/getUser", this.id);
      },
      passwordMatchRule() {
        return this.password1 === this.password2 || "Passwords must match";
      },
    },
  };
  </script>
  